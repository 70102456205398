<template>
	<v-container v-if="apiLoaded">
		<h1>
			<span>{{ legalEntity.fullName | df }} {{ legalEntity.shortName ? '(' + legalEntity.shortName + ')' : ''
			}}</span>
			<div>
				<v-btn @click="$router.push('/counterpartyEdit/' + orgId)">
					Редактировать
				</v-btn>
			</div>
		</h1>

		<section class="counterparty">

			<div class="grid30">
				<div class="div1">
					<div>
						<h4>Тип клиента</h4>
						<p>{{ legalEntity.clientType != null ? clientTypeDict[legalEntity.clientType] : '—' }}</p>
					</div>

					<div v-if="legalEntity.type === 'FL' || legalEntity.type === 'IP'">
						<h4>Фамилия</h4>
						<p>{{ legalEntity.surname | df }}</p>
					</div>

					<div v-if="legalEntity.type === 'FL' || legalEntity.type === 'IP'">
						<h4>Имя</h4>
						<p>{{ legalEntity.firstName | df }}</p>
					</div>

					<div v-if="legalEntity.type === 'FL' || legalEntity.type === 'IP'">
						<h4>Отчество</h4>
						<p>{{ legalEntity.middleName | df }}</p>
					</div>


					<div>
						<h4>ИНН</h4>
						<p>{{ legalEntity.inn | df }}</p>
					</div>

					<div v-if="legalEntity.type !== 'FL'">
						<h4>КПП</h4>
						<p>{{ legalEntity.kpp | df }}</p>
					</div>

					<div v-if="legalEntity.type === 'ORG' || legalEntity.type === 'TCST'">
						<h4>ОГРН</h4>
						<p>{{ legalEntity.ogrn | df }}</p>
					</div>

					<div v-if="legalEntity.type === 'IP'">
						<h4>ОГРНИП</h4>
						<p>{{ legalEntity.ogrnip | df }}</p>
					</div>

					<div v-if="legalEntity.type === 'ORG' || legalEntity.type === 'TCST'">
						<h4>ОКПО</h4>
						<p>{{ legalEntity.okpo | df }}</p>
					</div>


					<div v-if="legalEntity.type === 'TCST'">
						<h4>Рег. номер</h4>
						<p>{{ legalEntity.regnum | df }}</p>
					</div>

					<div v-if="legalEntity.type === 'TCST'">
						<h4>Уровень</h4>
						<p>{{ legalEntity.level != null ? customerLevelsDict[legalEntity.level] : '—' }}</p>
					</div>

					<div v-if="legalEntity.type === 'TCST'">
						<h4>Часовой пояс</h4>
						<p>{{ legalEntity.customerTimeZone != null ? customerTimeZoneDict[legalEntity.customerTimeZone]
								: '—'
						}}</p>
					</div>

					<div>
						<h4>Банк</h4>
						<p>{{ legalEntity.bank | df }}</p>
					</div>

					<div>
						<h4>Бик</h4>
						<p>{{ legalEntity.bik | df }}</p>
					</div>
					<div>
						<h4>Корр. счет</h4>
						<p>{{ legalEntity.correspondentAccountNum | df }}</p>
					</div>

					<div>
						<h4>Расчетный счет</h4>
						<p>{{ legalEntity.paymentAccountNum | df }}</p>
					</div>
					<div>
						<h4>Комментарий</h4>
						<p>{{ legalEntity.comment | df }}</p>
					</div>





				</div>

				<div class="div2">


					<h2>Общая информация</h2>
					<div>
						<img :src="getImagePath(legalEntity.imageFileId)"
							style="display:block;max-width:200px;max-height:200px;"
							v-if="legalEntity.imageFileId" />
						<a href="#" @click.prevent="$refs.imginput.$refs.input.click()"
							v-if="!legalEntity.imageFileId">Загрузить изображение</a>
						<a href="#" @click.prevent="deleteImage()" v-else style="font-size:75%">Удалить изображение</a>
						<v-file-input style="display:none" ref="imginput" @change="uploadImage($event)"
							accept="image/*">
						</v-file-input>
					</div>



					<div>
						<h4>Юридический адрес</h4>
						<p><a target="_blank" :href="'https://maps.yandex.ru/?text='+legalEntity.postAddress">{{ legalEntity.postAddress | df }}</a></p>
					</div>

					<div>
						<h4>фактический адрес</h4>
						<p><a target="_blank" :href="'https://maps.yandex.ru/?text='+legalEntity.factAddress">{{ legalEntity.factAddress | df }}</a></p>
					</div>


					<div>
						<h4>телефон</h4>
						<p>{{ legalEntity.phone | df }}</p>
					</div>

					<div>
						<h4>электронная почта</h4>
						<p>{{ legalEntity.email | df }}</p>
					</div>

					<div>
						<h4>сайт</h4>
						<p>{{ legalEntity.website | df }}</p>
					</div>

				</div>

			</div>
			<div class="grid30" v-if="legalEntity.problematic">



				<div class="div1">
					<h2>Проблемный заказчик</h2>

					<div>
						<h4>Тип проблемного заказчика</h4>
						<p>{{ legalEntity.problemCustomerType != null ?
								problemCustomerTypeDict[legalEntity.problemCustomerType] : '—'
						}}</p>
					</div>
					<div>
						<h4>Организация, по проекту которой произошел спор</h4>
						<p>{{ legalEntity.ourProblemOrg | df }}</p>
					</div>
					<div>
						<h4>Структурное подразделение</h4>
						<p>{{ legalEntity.problemDivision | df }}</p>
					</div>
					<div>
						<h4>ФИО сотрудников заказчика</h4>
						<p>{{ legalEntity.problemFIO | df }}</p>
					</div>
					<div>
						<h4>Должности сотрудников заказчика</h4>
						<p>{{ legalEntity.problemPost | df }}</p>
					</div>
					<div>
						<h4>Проект, по которому произошел спор</h4>
						<p>{{ legalEntity.problemProject | df }}</p>
					</div>
					<div>
						<h4>Основные причины и факторы</h4>
						<p>{{ legalEntity.problemReason | df }}</p>
					</div>
					<div>
						<h4>Ключевые этапы разбирательства</h4>
						<p>{{ legalEntity.problemStages | df }}</p>
					</div>
					<div>
						<h4>Принятое решение</h4>
						<p>{{ legalEntity.problemDecision | df }}</p>
					</div>
					<div>
						<h4>Даты спора</h4>
						<p>{{ legalEntity.problemDates | df }}</p>
					</div>


				</div>
				<div class="">
					<h2>Ссылки</h2>

					<p><a href="#">Договоры (заказчик)</a></p>
					<p><a href="#">Договоры (подрядчик)</a></p>
					<p><a href="#">Проекты (заказчик)</a></p>
					<p><a href="#">Проекты (соисполнитель)</a></p>
					<p><a href="#">Письма</a></p>
					<p><a href="#">Тендеры в СУП</a></p>
					<p><a href="#">Заявки на тендеры</a></p>

				</div>

			</div>

		</section>
	</v-container>
</template>


<script>
import api from '../../modules/api'
import { clientTypeDict, customerLevelsDict, customerTimeZoneDict, problemCustomerTypeDict } from '@/modules/NSI'
import { dictToObject, getImagePath } from '@/modules/CommonUtils'


export default {
	name: 'CounterpartyComponent',
	data() {
		return {
			orgId: null,
			legalEntity: {},
			apiLoaded: false,
			apiError: false,
			clientTypeDict: dictToObject(clientTypeDict),
			customerLevelsDict: dictToObject(customerLevelsDict),
			customerTimeZoneDict: dictToObject(customerTimeZoneDict),
			problemCustomerTypeDict: dictToObject(problemCustomerTypeDict),
		}
	},
	methods: {
    getImagePath,
		async uploadImage(e) {
			let formData = new FormData()
			formData.append('file', e)
			console.log('loading')
			let req = await api.postFormData('/supmain/legalentities/' + this.legalEntity.id + '/image', formData)
			if (req.ok)
				window.location.reload()
			else
				alert('Ошибка загрузки файла')
		},

		async deleteImage() {
			if (window.confirm('Вы действительно хотите удалить изображение?')) {
				let req = await api.delete('/supmain/legalentities/' + this.legalEntity.id + '/image')
				if (req.ok)
					window.location.reload()
				else
					alert('Ошибка загрузки файла')
			}
		}
	},
	async beforeMount() {
		this.orgId = this.$route.params.id
		if (this.orgId != null) {
			let req = await api.get('/supmain/legalentities/' + this.orgId)
			if (req.ok) {
				this.legalEntity = req.payload
				this.apiLoaded = true
			} else {
				this.apiError = true
			}
		}
		else
			this.apiError = true
	}

}
</script>

<style scoped>
</style>
